import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'

const PhoneNumberForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [aadharNumber, setAadharNumber] = useState('');
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setError('');
    } else {
      setError('Phone number must be exactly 10 digits and contain only numbers.');
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDateOfBirthChange = (date) => {
    setDateOfBirth(date);
    if (isUnderage(date)) {
      setError('You must be 18 years or older.');
    } else {
      setError('');
    }
  };

  const handleAadharNumberChange = (e) => {
    const value = e.target.value;
    setAadharNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length !== 10) {
      setError('Phone number must be exactly 10 digits.');
      return;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError('Phone number must contain only digits.');
      return;
    }
    if (!name) {
      setError('Please enter your name.');
      return;
    }
    if (!dateOfBirth) {
      setError('Please select your date of birth.');
      return;
    }
    if (isUnderage(dateOfBirth)) {
      setError('You must be 18 years or older.');
      return;
    }
    if (!aadharNumber) {
      setError('Please enter your Aadhar number.');
      return;
    }
    if (!frontPhoto || !backPhoto) {
      setError('Please upload both photos.');
      return;
    }
    setError('');
    handleFormSubmission();
  };

  const isUnderage = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 < 18;
    }
    return age < 18;
  };

  const handleFrontPhotoChange = (e) => {
    const [file] = e.target.files;
    setFrontPhoto(file);
  };

  const handleBackPhotoChange = (e) => {
    const [file] = e.target.files;
    setBackPhoto(file);
  };

  const handleFormSubmission = () => {
    const email = '';
    const formData = new FormData();
    formData.append('Name', name);
    formData.append('PhoneNumber', phoneNumber);
    formData.append('DOB', dateOfBirth);
    formData.append('Number', aadharNumber);
    formData.append('front', frontPhoto);
    formData.append('back', backPhoto);
    formData.append('Email', email);

    if (frontPhoto && backPhoto) {
      const access_token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(baseUrl + `manualKyc`, formData, { headers })
        .then((res) => {
          Swal.fire({
            title: res.data.msg,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          setSubmitted(true);
        })
        .catch((e) => {
          setError('An error occurred while submitting the form. Please try again later.');
        });
    } else {
      setError('Please fill all fields.');
    }
  };

  if (submitted) {
    return (
      <div className="container mt-5">
        <h2 className="text-success">Form Submitted Successfully!</h2>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="container mt-5">
      <div className="mb-3">
        <label htmlFor="phoneNumber" className="form-label fw-bold text-black">
         User Phone Number
        </label>
        <input
          type="text"
          id="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Enter 10-digit phone number"
          required
          className="form-control"
          style={{ width: '40%' }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="name" className="form-label fw-bold text-black">
          User Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={handleNameChange}
          placeholder="Enter your name"
          required
          className="form-control"
          style={{ width: '40%' }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="dateOfBirth" className="form-label fw-bold text-black">
          Date of Birth
        </label>
        <br />
        <DatePicker
          id="dateOfBirth"
          selected={dateOfBirth}
          onChange={handleDateOfBirthChange}
          dateFormat="MM/dd/yyyy"
          showYearDropdown
          scrollableYearDropdown
          className="form-control"
          style={{ width: '40%' }}
          required
        />
      </div>

      <div className="mb-3">
        <label htmlFor="aadharNumber" className="form-label fw-bold text-black">
          Aadhar Number
        </label>
        <input
          type="text"
          id="aadharNumber"
          value={aadharNumber}
          onChange={handleAadharNumberChange}
          placeholder="Enter your Aadhar number"
          required
          className="form-control"
          style={{ width: '40%' }}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="frontPhoto" className="form-label fw-bold text-black">
          Upload Front Photo
        </label>
        <input
          id="frontPhoto"
          name="frontPhoto"
          type="file"
          accept="image/*"
          onChange={handleFrontPhotoChange}
          required
          className="form-control"
          style={{ width: '40%' }}
        />
        {frontPhoto && <div className="mt-2 text-muted">{frontPhoto.name}</div>}
      </div>

      <div className="mb-3">
        <label htmlFor="backPhoto" className="form-label fw-bold text-black">
          Upload Back Photo
        </label>
        <input
          id="backPhoto"
          name="backPhoto"
          type="file"
          accept="image/*"
          onChange={handleBackPhotoChange}
          required
          className="form-control"
          style={{ width: '40%' }}
        />
        {backPhoto && <div className="mt-2 text-muted">{backPhoto.name}</div>}
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>

      {error && <div className="text-danger mt-2">{error}</div>}
    </form>
  );
};

export default PhoneNumberForm;
