import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function UpdatePassword() {
  const history = useHistory()

  const [newUpiID, setNewUpiID] = useState()
  const [confirmUpiID, setConfirmUpiID] = useState()
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const addAdmin = async (e) => {
    e.preventDefault()

    const access_token = localStorage.getItem('token')

    const headers = {
      Authorization: `Bearer ${access_token}`,
    }

    if (newUpiID !== confirmUpiID) {
      alert("UPIIds don't match")
    } else {
      const data = await axios
        .post(
          baseUrl + 'changeUPIId',
          {
            newUpiID,
            confirmUpiID,
          },
          { headers },
        )
        .then((res) => {
          history.push('/admin/alladmins')
        })
    }
  }

  return (
    <div className="other_page_cards_main">
      <h4 className="other_page_cards_heading my-3">Update UPI ID</h4>

      <form id="add_admin_form" onSubmit={(e) => addAdmin(e)}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="upiid">New UPI ID</label>
            <input
              type="text"
              className="form-control"
              id="upiid"
              name="upiid"
              placeholder="New UPI ID"
              onChange={(e) => {
                setNewUpiID(e.target.value)
              }}
              required
            />
          </div>
          {/* </div>
        <div className="form-row"> */}
          <div className="form-group col-md-4">
            <label htmlFor="upiid">Confirm UPI ID</label>
            <input
              type="text"
              className="form-control"
              id="upiid"
              name="upiid"
              placeholder="Confirm UPI ID"
              onChange={(e) => {
                setConfirmUpiID(e.target.value)
              }}
              required
            />
          </div>
          {/* </div>
        <div className="form-row"> */}
        </div>
        <div>
          <button type="submit" className="btn  btn-success">
            update
          </button>
        </div>
      </form>
    </div>
  )
}
