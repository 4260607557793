import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const $ = require('jquery')
$.Datatable = require('datatables.net')

export default function New() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }
  const [challenge, setchallenge] = useState()

  const Allchallenge = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`,
    }
    axios.get(baseUrl + `challange/new_challange`, { headers }).then((res) => {
      //console.log(res)
      setchallenge(res.data)
      $('table').dataTable()
    })
  }

  const CancelGame = async (id) => {
    const confirm = window.confirm('are you sure to cancel')

    if (confirm == true) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`,
      }
      axios
        .patch(
          baseUrl + `challange/Cancel/${id}`,
          { Cancelled_by: access_token },
          { headers },
        )
        .then((res) => {
          Allchallenge()
        })
    } else {
      window.alert('sorry try again')
    }
  }
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const dateFormat = (e) => {
    if (e === null)
    {
      return ''
    }
    let date = new Date(e);
    let day = date.getUTCDate();
    let month = months[date.getUTCMonth()];
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes().toString().padStart(2, '0');
    
    // Determine AM/PM and format the hour
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;  // Convert to 12-hour format, with 0 as 12
    
    // Format the date into the desired string format
    let formattedDate = `${day} ${month} at ${hours}:${minutes} ${period}`;

    return formattedDate
  }

  useEffect(() => {
    Allchallenge()
  }, [])

  if (challenge == undefined) {
    return null
  }

  return (
    <>
      <div className="other_page_cards_main">
        {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
        <div className="row ">
          <div className="col-12 grid-margin">
            <div className="">
              <div className="">
                <h4 className="other_page_cards_heading">New Challanges</h4>
                <div className="table-responsive">
                  <table className="table other_page_table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> ID</th>
                        <th> Creator</th>
                        <th> Amount </th>
                        <th> Status </th>
                        <th> Game Type </th>
                        <th>Date</th>
                        <th> Action </th>
                      </tr>
                    </thead>

                    <tbody>
                      {challenge &&
                        challenge.map((game, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{game._id}</td>
                            <td>
                              <span className="pl-2 ">
                                {game.Created_by.Name}
                              </span>
                            </td>

                            <td>{game.Game_Ammount}</td>
                            <td className="text-primary font-weight-bold">
                              {game.Status}
                            </td>
                            <td>{game.Game_type}</td>
                            <td>{dateFormat(game.createdAt)} </td>
                            <td>
                              <Link
                                type="button"
                                className="btn btn-primary mx-1"
                                to={`/view/${game._id}`}
                              >
                                View
                              </Link>
                              {/* {game.Status != "cancelled" && game.Status != "completed" && game.Status != "conflict" && <button type='button' className="btn  mx-1 btn-danger" onClick={() => CancelGame(game._id)}>Cancel</button>} */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
